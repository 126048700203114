.App {
    margin-top: 48px;
}

button.btn-magenta {
    background-color: #e20074 !important;
    color: #ffffff;
}

button.btn-magenta:hover {
    background-color: #e370a3 !important;
    color: #ffffff;
}

.flatpickr-day.selected,
.flatpickr-weekdaycontainer span.flatpickr-weekday,
.flatpickr-weekdays,
.numInputWrapper input,
.flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-next-month path, .flatpickr-prev-month path {
    background-color: #e20074 !important;
    border: #e20074 !important;
    color: white !important;
    fill: white !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: #ec509d !important;
}

.flatpickr-input input:hover {
    background: #e370a3 !important;
}

.datePicker input:hover {
    border-color: #e370a3 !important;
}

&:focus {
    border-color: #e370a3 !important;
    outline: none !important;
}

&:hover {
    border-color: #e370a3 !important;
    outline: none !important;
}

.calendar > svg > path {
    fill: #ec509d !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    border-bottom-color: rgb(226, 0, 116) !important;
}

.paginationColored{
    background-color: #e370a3 !important;
    color: #ffffff !important;
}

.paginationColored:hover {
    background-color:  #e20074 !important;
    color: #ffffff;
}

.paginationColored:focus {
    background-color: #e20074 !important;
    color: #ffffff;
}

